<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        :title="getRes('Document.Query')"
        :cards="tendererList"
        hide-count-badge
        v-slot="{ card }"
      >
        <pro-deck-card :title="card.title" expandable>
          <template #status>
            <div class="q-ml-md text-grey-6">
              {{ card.emailAddress }}
            </div>
          </template>

          <pro-deck-card-time-map :entries="card.queries" />
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeckCardTimeMap from "@/components/PROSmart/Deck/Body/ProDeckCardTimeMap";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard, ProDeckCardTimeMap },
  data() {
    return {
      tendererList: [],
    };
  },
  created() {
    this.$proSmart.tender
      .getTendererQueryList(this, this.$route.params.id)
      .then((tendererList) => {
        this.tendererList = tendererList.map((tenderer) => ({
          key: tenderer.hcmUserId,
          title: tenderer.name,
          emailAddress: tenderer.emailAddress,
          queries: tenderer.queries.map((query) => ({
            key: query.queryId,
            title: this.$proSmart.common.format.dateTime(
              new Date(query.dateSubmitted)
            ),
            action: {
              processId: query.processId,
            },
          })),
        }));
      });
  },
};
</script>
