<template>
  <div>
    <q-timeline class="q-pl-sm">
      <q-timeline-entry
        v-for="entry in entries"
        :key="entry.key"
        :color="entry.unread ? unreadColor : color"
      >
        <div class="row items-center q-col-gutter-x-md">
          <div class="col-auto self-start">
            <div
              class="text-h4"
              :class="entry.unread ? `text-${unreadColor}` : ''"
            >
              {{ entry.title }}
            </div>
            <div v-if="entry.date" class="text-h6 text-grey-6">
              {{ $proSmart.common.format.dateTime(entry.date) }}
            </div>
          </div>
          <q-space />
          <div v-if="entry.label" class="col-auto">{{ entry.label }}</div>
          <div v-if="entry.action" class="col-auto">
            <q-btn-group outline v-if="entry.action.processId">
              <q-btn
                no-caps
                icon="PROSmart-Docs"
                :label="$t('System.Button.View')"
                @click.stop="
                  showRecord(entry.action.processId, entry.action.viewForm)
                "
              />
            </q-btn-group>
            <pro-button
              v-else
              :color="entry.action.color || 'primary'"
              :label="entry.action.label"
              :to="entry.action.to"
              :disable="disable"
            />
          </div>
        </div>
      </q-timeline-entry>
    </q-timeline>
    <div v-show="entries.length === 0" class="text-center q-pa-md">
      {{ noResultsLabel || getRes("Form.Message.Error.NoRecordFound") }}
    </div>
    <audit-trail-record-dialog ref="audit" />
  </div>
</template>

<script>
import AuditTrailRecordDialog from "@/components/PROSmart/AuditTrailRecordDialog";
import ProButton from "@/components/PROSmart/ProButton";

export default {
  name: "ProDeckCardTimeMap",
  components: { AuditTrailRecordDialog, ProButton },
  props: {
    /** @type {Array<{key: string, title: string, date: Date, label: string, action: {label: string, color: string, to: Location}, unread: boolean}>} */
    entries: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "primary",
    },
    unreadColor: {
      type: String,
      default: "unread",
    },
    disable: Boolean,
    noResultsLabel: String,
  },
  methods: {
    showRecord(processId, viewForm) {
      if (viewForm) {
        this.$refs.audit.showInfoViewForm(
          processId,
          this.$route.params.id,
          true
        );
      } else {
        this.$refs.audit.showInfo(processId, this.$route.params.id, true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.q-timeline__entry::v-deep {
  .q-timeline__subtitle,
  .q-timeline__title {
    margin-bottom: 0;
  }

  &:last-child {
    .q-timeline__content {
      padding-bottom: 0;
    }
  }
}
</style>
